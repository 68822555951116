import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
} from '@material-ui/core';
import {
  IconButton,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { logout } from '../actions/auth';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'


const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  }
}));

const TopBar = ({ props, className, logout, ...rest }) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const staff_url = location.pathname.replace(/^\/([^\/]*).*$/, '$1')

	const logOut = () => {
		logout()
		return navigate('/login')
	}

	return (
		<AppBar
			className={clsx(classes.root, className)}
			elevation={0}
			{...rest}
		>
		<Toolbar className={classes.toolbar}>
			<RouterLink to="/">
			</RouterLink>
			<Box flexGrow={1} />
			{staff_url=='staff' ? (
				<IconButton color="inherit" onClick={logOut}><InputIcon /></IconButton>
			) : null}
		</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default connect(null,{  logout })(TopBar);

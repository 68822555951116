import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function TreeForm({handleTreeDetails, tree}) {
  console.log(tree);
  const treeDetails = {"tree":[]};
  const [treeVar, setTreeVar] = React.useState(tree);

  const handleChange = event => {
    setTreeVar([{
      [event.target.name]: event.target.value
    }]);
    const tempDetail = [{
      "uuid": tree[0].uuid,
      "serial": document.getElementById('serial').value,
      "breed": document.getElementById('breed').value,
      "age": document.getElementById('age').value,
      "height": document.getElementById('height').value,
      "diameter": document.getElementById('diameter').value,
      "horizontal_line": document.getElementById('horizontal_line').value,
      "latitude": document.getElementById('latitude').value,
      "longitude": document.getElementById('longitude').value,
      "remark": document.getElementById('remark').value,
      "image":""
    }];
    treeDetails.tree = tempDetail;
    console.log(tempDetail);
    handleTreeDetails(tempDetail);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Tree Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="serial"
            name="serial"
            label="Serial"
            value={treeVar[0].serial}
            fullWidth
            autoComplete="serial"
            InputLabelProps={{ shrink: true }}  
            onChange = {handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="breed"
            name="breed"
            value={treeVar[0].breed}
            label="Breed"
            fullWidth
            autoComplete="breed"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="age"
            name="age"
            value={treeVar[0].age}
            label="age"
            fullWidth
            autoComplete="age"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="height"
            name="height"
            value={treeVar[0].height}
            label="Height"
            fullWidth
            autoComplete="height"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}

          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="diameter"
            name="diameter"
            value={treeVar[0].diameter}
            label="Diameter"
            fullWidth
            autoComplete="diameter"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}

          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="horizontal_line"
            name="horizontal_line"
            value={treeVar[0].horizontal_line}
            label="Horizontal Line"
            fullWidth
            autoComplete="horizontal_line"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}

          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="latitude"
            name="latitude"
            value={treeVar[0].latitude}
            label="Latitude"
            fullWidth
            autoComplete="latitude"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}

          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField 
            required id="longitude" 
            name="longitude" 
            value={treeVar[0].longitude}
            label="Longitude" 
            fullWidth 
            onChange = {handleChange}
            InputLabelProps={{ shrink: true }} 

            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="remark"
            name="remark"
            value={treeVar[0].remark}
            label="Remark"
            fullWidth
            autoComplete="remark"
            InputLabelProps={{ shrink: true }} 
            onChange = {handleChange}

          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

// TreeForm.propTypes = {
//   treeDetails: PropTypes.func
// };

// TreeForm.defaultProps = {
//   treeDetails: (data) => {}
// };
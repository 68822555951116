import React, {useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { storeDisease, loadOptions } from '../../../actions/form';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';

const AdminDiseaseForm = ({ diseases, storeDisease, tree, loadOptions, options }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [disease, setDisease] = React.useState('');
    const [diseaseAmount, setDiseaseAmount] = React.useState(1);
    const [img, setImg] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(async() => {
        setDisease(diseases['disease'])
        setDiseaseAmount(diseases['disease_amount'])
        setImg(diseases['disease_images'])
        loadOptions('disease', setLoading)
    }, [diseases])

    const selectChange = (e) => {
        setDisease(e.target.value)
    }
    const amountChange = (e) => {
        setDiseaseAmount(e.target.value)
    }
    const clear = () => {
        setDisease('')
        setDiseaseAmount(1)
        setImg([])
    }
    const save = () => {
        storeDisease({
            'diseases': {
                'disease': disease,
                'disease_amount': diseaseAmount,
                'disease_images': img
            }
        })
        return navigate('/app/form/')
    }

    const handleChangeImage = e => {
        // For now its one-by-one hence the [0]
        console.log(URL.createObjectURL(e.target.files[0]))
        e.target.files[0]['image_blob'] = URL.createObjectURL(e.target.files[0])
        console.log('handleChangeImage', e.target.files[0])
        setImg([
            ...img,
            e.target.files[0]
        ])
    }
    const imageNotFound = () =>{
        alert("Image Not Found")
    }
    const deleteImg = (index) => {
        console.log('deleteImg', index)
        setImg(img.filter((val,key) => key !== index))
    }

    const back = () => {
        clear()
        return navigate('/app/form/')
    }
    
    return (
        <div className={classes.root}>
            {loading && (
                <div className={classes.loader}>
                    <CircularProgress/>
                </div>
            )}
            <h3>Disease</h3>

            <FormControl className={classes.formControl}>
                <>
                    <InputLabel id="disease-label" required>Disease</InputLabel>
                    <Select
                        labelId="disease-label"
                        id="disease"
                        value={disease}
                        onChange={e => selectChange(e)}
                    >
                    {options.map( (key,value) => {
                        return (
                            <MenuItem key={value} value={key}>{key}</MenuItem>
                        )
                    })}
                    </Select>
                </>
                <>
                    <TextField label="Amount" value={diseaseAmount} type="number" onChange={e => amountChange(e)} required/>
                </>
                <div className={classes.imgContainer} >
                {img.map( (val, key) => {
                    return(
                        <div style={{position:'relative'}}  key={key}>
                            <span onClick={() => deleteImg(key)}>x</span>
                            <img className={classes.imgPlaceHolder} src={val['image_blob']} alt="image" onError={imageNotFound}/>
                        </div>
                    )
                })}
                </div>
            </FormControl>

            <Box display="flex" justifyContent="flex-start">
                <Button variant="contained" component="label" color="primary" style={{width:'100%', marginBottom: '25px'}}>
                    Upload Image
                    <input
                        accept="image/*"
                        id="imgDisease"
                        // multiple
                        name="imgDisease"
                        type="file"
                        capture
                        hidden
                        onChange={handleChangeImage}
                    />
                </Button>
            </Box>

           
            <Grid container className={classes.submitRow}>
                <Grid item sm={6} xs={12} style={{textAlign:'start', marginTop: '10px', paddingRight: '5px'}}>
                    <Button style={{width: '100%'}}
                        variant="outlined"
                        color="secondary"
                        onClick={back}
                        endIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Grid>
                <Grid item sm={3} xs={6} style={{marginTop: '10px'}}>
                    <Button style={{width: '100%'}}
                        variant="contained"
                        color="secondary"
                        onClick={clear}
                        endIcon={<DeleteIcon />}>
                        Clear
                    </Button>
                </Grid>
                <Grid item sm={3} xs={6} style={{marginTop: '10px', paddingLeft: '5px'}}>
                    <Button style={{width: '100%'}}
                        variant="contained"
                        color="primary"
                        onClick={save}
                        endIcon={<SaveIcon />}>
                        Save
                    </Button>
                </Grid>
            </Grid>

        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 60px'
    },
    formControl: {
        width: '100%',
        marginTop: '20px',
        '& > div' : {
            margin: '20px 0',
        }
    },
    submitRow: {
        textAlign: 'center',
    },
    imgContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        position: 'relative',
        '& span': {
            position:'absolute', 
            top:0, 
            right:0,
            background:'grey',
            borderRadius: '50%',
            height: '25px',
            width: '25px',
            textAlign: 'center',
        }
    },
    imgPlaceHolder: {
        margin: '10px 15px',
        display:'block',
        height:'100px',
        width:'100px',
    },
    loader: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        right: 0,
        background: '#fff',
        opacity: 0.7,
        textAlign: 'center',
        
    }
}));  
const mapStateToProps = state => ({
    diseases: state.form.diseases,
    tree: state.form.tree,
    options: state.form.options,
});
export default connect(mapStateToProps, { storeDisease, loadOptions })(AdminDiseaseForm)
// rfce

import {React, useEffect, useState, setState} from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '../views/Table'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Services from '../helpers/Services';
import { clearTreeView } from '../actions/tree';

const Trees = ({ isAuthenticated, clearTreeView }) => {
    const [data, setData] = useState([]); 
    const navigate = useNavigate();

    const addnewrecord = ()=>{
        navigate('/app/trees/add');
    };

    clearTreeView();

    useEffect(() => {
        Services.listTrees().then((res)=>{
            setData(res.tree)
        },
        (err)=>{
            console.log('Trees',err)
        });
      }, []);

    // if(!isAuthenticated)
    //     return  <Navigate to='/login'/>;
    
    if(data.length === 0 ){
        return (
            <div className='container'>
                <h3>Loading...</h3>
            </div>
          )
    }else{
        return (
            <div className='container'>
                <Grid container justify="flex-end" style={{margin:'20px 0'}}>
                    <Button variant="contained" color="primary" onClick={addnewrecord}>
                        Add New Record
                    </Button>
                </Grid>
                <Table data={data}/>
            </div>
        )
    }
      
    
    
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {clearTreeView})(Trees);

import React, { useEffect,useState, useRef } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { clearAll } from '../../../actions/form'
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import Amplify, { Auth, Storage } from 'aws-amplify';
// import { loadTree } from '../../actions/form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import API from '../../../helpers/api'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QrReader from 'react-qr-reader'

Amplify.configure({
    Auth: {
        identityPoolId: 'ap-southeast-1:306b09dd-a9be-40b4-b8eb-b14ffcb5d48b', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-southeast-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: `${process.env.REACT_APP_AWS_URL}`, //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
            level: "public",
        }
    }
});


function formatDate(date) {
    let year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour,
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hourFormatted < 10) {
            hourFormatted = "0" + hourFormatted;
        }
        if (second < 10) {
            second = "0" + second;
        }
    return year + "-" + month + "-" + day + "T" + hourFormatted + ":" + minuteFormatted + ":" + second;
}
const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

let init = false;
let serialList = [];
let uuid = '';
let serial2 = '';

const AdminFormLayout = ({user, form, clearAll, isAuthenticated})  => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [modalStatus, setModalStatus] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [serial, setSerial] = useState(serial2)
    const [loading, setLoading] = useState(false)
    const [categoryMarker, setCategoryMarker]  = React.useState({
        'fertilizer': '',
        'pest': '',
        'pesticide': '',
        'disease': '',
        'fungicide': '',
    })
    const [inputValue, setInputValue] = useState('')
    const [scanQrModal, setScanQrModal] = useState(false)

    let query = new URLSearchParams(useLocation().search);
    const APP_URL = `${process.env.REACT_APP_APP_URL}/`

    useEffect( async () => {
        if (!init) {
            setLoading(true)
            const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getRecordList/`, config);
            let temp = []
            for (let i of res.data.tree) {
                temp.push({
                    'serial': i.serial,
                    'uuid': i.uuid
                })
            }
            serialList = temp
            setLoading(false)
            init = true
        }
        return () => {
        }
    }, [])

        

    // check state of form
    useEffect(() => {
        for (let i in form) {
            let lastIndex = `${i}`.lastIndexOf("s");
            let category = `${i}`.substring(0, lastIndex);
            if (i !== 'tree' && i != 'options') {

                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (i == 'pests' || i =='diseases') {
                    if (form[i][`${category}_images`].length > 0 || form[i][category].length > 0) {
                        setCategoryMarker( prevState => ({
                            ...prevState,
                            [category]: '✅'
                        }))
                    } else {
                        setCategoryMarker( prevState => ({
                            ...prevState,
                            [category]: ''
                        }))
                    }

                } else {
                    if (form[i][category].length > 0) {
                        setCategoryMarker( prevState => ({
                            ...prevState,
                            [category]: '✅'
                        }))
                    } else {
                        setCategoryMarker( prevState => ({
                            ...prevState,
                            [category]: ''
                        }))
                    }
                }
            }
        }
        console.log(12, categoryMarker)
    }, [form])

    // if(!isAuthenticated) {return  <Navigate to='/login'/>};
    
    // useEffect( async () => {
    //     setSerial(form.tree.serial)
    // }, [form])

    const handleNav = (nav) => {
        console.log('handleNav', '/app/form/'+nav)
        return navigate('/app/form/'+nav);
        
    }
    const confirmation = () => {
        if (window.confirm('Are you sure you want to clear all saved inputs?')) {
            clearAll()
        }
    }
    const closeModal = () => {
        setModalStatus(false);
    };
    const openModal = () => {
        setModalStatus(true);
    };

    const submitForm = async() => {
        console.log('submitForm', uuid)

        if (serial == '' || serial == null) return  alert('Please select a serial.') 
        //check if submitting empty form
        let count=0
        Object.keys(form).map(function(key) {
            let lastIndex = `${key}`.lastIndexOf("s");
            let category = `${key}`.substring(0, lastIndex);
            if (key!=='tree' && key!='options') {


                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (key == 'pests' || key == 'diseases' ) {
                    if (form[key][`${category}_images`].length > 0 || form[key][category].length > 0) {
                        count ++
                    }
                } else {
                    if (form[key][`${category}`] !== '') {
                        count ++
                    }
                }
            }
        });
        if (count == 0) return alert('Please filled in a form before submitting.') 

        // let username = user ? user.username : ''
        let datetime = new Date()
        let image_detail = []
        let data = []
        
        for (let i in form ) {
            let lastIndex = `${i}_amount`.lastIndexOf("s");
            let category = `${i}_amount`.substring(0, lastIndex);
            if ((form[i][`${category}`] !== '') && (i != 'tree' && i != 'options')) {
                if (form[i][`${category}_images`] && form[i][`${category}_images`].length>0) {
                    // add images_name as an array into data
                    let counter = 0;
                    let datetime = formatDate(new Date())
                    let image_arr = []
                    form[i][`${category}_images`].map(val => {
                        let image_name = datetime + '_'+ counter + '.jpg'
                        image_arr.push(image_name)
                        image_detail.push({
                            'image_name': image_name,
                            'image_file': val,
                            'category': category
                        })
                        counter++
                    })

                    data.push({
                        // ...form[i],
                        "uuid": uuid,
                        'datetime': datetime,
                        'username': user.username,
                        'category': category,
                        'type': form[i][`${category}`] ,
                        "amount": form[i][`${category}_amount`],
                        "images": image_arr,
                    })
                    counter++
                } else {
                    data.push(
                        {
                            "uuid": uuid,
                            'datetime': datetime,
                            'username': user.username,
                            'category': category,
                            'type': form[i][`${category}`] ,
                            "amount": form[i][`${category}_amount`],
                        }
                    )
                }
            }
        }
        console.log(data)
        

        if (localStorage.getItem('access')) {
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
            };


            setSubmitting(true)
            axios.post(`${process.env.REACT_APP_API_URL}/tree/saveEventList/`, {
                "event": data
            }, config)
            .then(function (response) {
                console.log('submitting', response);
                // Submit data with images only.
                delete axios.defaults.headers.common["Authorization"];
                image_detail.map(  img => {
                    // <serial>/<category>/timestamp_XX.jpg
                    console.log(68, img['image_file'], serial +'/'+ img['category'] + '/' + img['image_name'])
                    Storage.put(serial +'/'+ img['category'] + '/' + img['image_name'], img['image_file'], {
                        contentType: 'image/jpg',
                        level: 'public',
                        type: 'application/json'
                    })
                    .then ( async result => console.log(result))
                    .catch(err => console.log(err));
                })
                
                clearAll()
                setSubmitting(false)
                setModalStatus(false)
                alert('Succesfully Created!')
            })
            .catch(function (error) {
                console.log(error);
                clearAll()
                setSubmitting(false)
                alert('Something wrong!')
            });

        } else {
            console.log('Unauthorized. Please log in.')
        }
    }

    const SubmitContent = () => {
        let return_html=[]
        Object.keys(form).map(function(key, val) {
            if (key !='tree' && key != 'options') {
                let lastIndex = `${key}`.lastIndexOf("s");
                let category = `${key}`.substring(0, lastIndex);

                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (key == 'pests' || key == 'diseases' ) {
                    if (form[key][`${category}_images`].length > 0 || form[key][category].length > 0) {
                        return_html.push(<div key={key}>✅ &nbsp;  {category}</div>)
                    }
                } else {
                    if (form[key][`${category}`] !== '') {
                        return_html.push(<div key={key}>✅ &nbsp;  {category}</div>)
                    }
                }

            }
        });
        return return_html
    }

    const backToDashboard = () => {
        clearAll()
        return navigate('/app/home')
    }


    // SCAN QR FUNCTION
    const handleScan = data => {
        if (data) {
            setLoading(true)
            setScanQrModal(false)
            let uuid_result = data.replace(APP_URL,'')
			
            //GET SERIAL FROM UUID
            axios.get(`${process.env.REACT_APP_API_URL}/tree/getRecord/${uuid_result}`).then((res)=> {
                setLoading(false)
                console.log(res.data.tree[0].serial)

                serial2 = res.data.tree[0].serial
                setSerial(res.data.tree[0].serial)
                uuid = res.data.tree[0].uuid

            }).catch( err => {
                setLoading(false)
                console.log(err)
                alert('Invalid QR')
            })
        }
    }
    const handleError = err => {
        console.error(err)
    }
    return (
        <div className={classes.root} >
            {loading && (
                <div className={classes.loader}>
                    <CircularProgress/>
                </div>
            )}
            

            <Grid container>
                <Grid item lg={2} xs={12} style={{textAlign: 'center', alignSelf:'center', marginBottom:'10px'}}>
                    <Button variant="contained" color="primary" size="large" onClick={()=>setScanQrModal(true)}
                        endIcon={<Icon>camera</Icon>}>
                        Scan 
                    </Button>
                </Grid>
                <Grid item lg={10} xs={12}>
                    <Autocomplete
                        value={serial}
                        onChange={(event, newValue) => {
                            // console.log('setValue', newValue)
                            serial2 = newValue.serial
                            setSerial(newValue.serial)
                            uuid = newValue.uuid
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            // console.log('setInputValue', newInputValue)
                            setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={serialList}
                        getOptionLabel={(option) => {
                            // console.log('getOptionLabel', option.seriali)
                            if (option.serial) {
                                return option.serial.toString()
                            } else {
                                return option
                            }
                        }}
                        disableClearable={true}
                        renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
                    />
                </Grid>
            </Grid>
                
                
            
            
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} className={classes.row}>
                    <Button variant="contained" color="primary" onClick={() => handleNav('fertilizers')}>
                    Fertilizers {categoryMarker.fertilizer}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('pests')}>
                    <Button variant="contained" color="primary">
                        Pest {categoryMarker['pest']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('fungicides')}>
                    <Button variant="contained" color="primary">
                        Fungicide {categoryMarker['fungicide']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('diseases')}>
                    
                    <Button variant="contained" color="primary">
                        Disease {categoryMarker['disease']}
                        </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('pesticides')}>
                    <Button variant="contained" color="primary">
                        Pesticide {categoryMarker['pesticide']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row}>
                    <Button variant="contained" color="primary">
                        Fruits
                    </Button>
                </Grid>

                <Grid container className={classes.submitRow}>
                    <Grid item sm={6} xs={12}>
                        <Button 
                            variant="outlined"
                            color="primary"
                            onClick={backToDashboard}
                            style={{width: '70%'}}
                            endIcon={<Icon>home</Icon>}>
                            Dashboard
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Button  
                            variant="outlined"
                            color="secondary"
                            onClick={confirmation}
                            endIcon={<DeleteIcon />}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={openModal}
                            style={{backgroundColor: 'red !important'}}
                            endIcon={<Icon>check</Icon>}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>

            </Grid>


            <Modal
                open={scanQrModal}
                onClose={()=>setScanQrModal(false)}
                aria-labelledby="scan-modal-title"
                aria-describedby="scan-modal-description">
                <div className={classes.scanModalContainer}>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%'}}
                        showViewFinder={true}
                        className={classes.qrWrapper}
                    />
                </div>
            </Modal>
            
            <Modal
                open={modalStatus}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                    <div  className={classes.modalContainer}>
                        <h4>Submitted forms:</h4>
                        <SubmitContent/>

                        <Box display="flex" flexDirection="row-reverse" >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                disabled={submitting}
                                endIcon={<Icon>send</Icon>}>
                                {submitting &&(
                                <CircularProgress />
                                )}Send
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={closeModal}
                                disabled={submitting}
                                style={{ margin: '0 20px'}}>
                                Close
                            </Button>
                        </Box>
                    </div>
            </Modal>
        </div>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 60px'
    },
    row : {
        padding: '20px',
        textAlign: 'center',
        
        '& Button': {
            margin: theme.spacing(2),
            width: '85%',
        },
    },
    submitRow: {
        padding: '20px',
        textAlign: 'center',
        '& Button': {
            marginTop: '20px' ,
        },
    },
    modalContainer: {
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 'auto',
        marginTop: '200px',
    },
    selectContainer: {
        margin: '30px 0',
    },
    loader: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        right: 0,
        background: '#fff',
        opacity: 0.7,
        textAlign: 'center',
        
    },
    scanModalContainer: {
        width: '60%',
        margin: 'auto',
        backgroundColor: 'white',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
    qrWrapper:{
        '& section div':{
            border: '22px solid rgba(0, 0, 0, 0.3) !important'
        }
    }
}));  
const mapStateToProps = state => ({
    user: state.auth.user,
    form: state.form,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps , {clearAll})(AdminFormLayout);

// export default connect(mapStateToProps , {clearAll, loadTree})(AdminFormLayout);

{/* <Button display="flex" 
        variant="contained"
        color="primary"
        endIcon={<Icon>send</Icon>}>
        Send
    </Button> */}

{/* <Select
    id="serial-select"
    value={serial}
    onChange={(e, c) => ( selectChange(e, c) )}
>
    {serialList.map( (key,value) => {
        // console.log(key,value)
        return (
            <MenuItem value={key.serial} key={key.uuid} name={key.uuid} >{key.serial}</MenuItem>
        )
    })} 
</Select> */}
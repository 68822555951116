import axios from "axios";
import { ActionPictureInPicture } from "material-ui/svg-icons";
import API from "./api";

const API_URL =`${process.env.REACT_APP_API_URL}/tree/`;

class Services {
  listTrees() {
    return API
      .get(API_URL + "getRecordList/")
      .then(response => {

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("userData");
  }

}

export default new Services();
import {useEffect, useState, setState} from 'react';
import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { loadTree } from '../../actions/tree';

const ViewFarm = ({isAuthenticated, user, loadTree}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const username = user ? user.username : (<Navigate to='/login'/>)
    let { farm_name } = useParams();
    const columns = [
        { id: 'serial', label: 'Serial', minWidth: 100 },
        { id: 'breed', label: 'Breed', minWidth: 170 },
        { id: 'age', label: 'Age', minWidth: 100 },
    ];

    useEffect(() => {
        console.log('farm_name', farm_name)

        if (localStorage.getItem('access')) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                }
            };
            setLoading(true)
            axios.get(`${process.env.REACT_APP_API_URL}/tree/getFarmTree/${username}/${farm_name}`, config).then(function (response) {

                console.log(11, response); 
                setRows(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
        } else {
            alert('Unauthorized. Please log in.')
        }
        

    }, [farm_name]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (row) => {
        loadTree(row.serial, row.uuid);
        navigate('/app/trees/view');
    }

    // if(!isAuthenticated) return  <Navigate to='/login'/>; 


    if (loading) {
        return (
            <div className='container'>
                <h3>Loading...</h3>
            </div>
        )
    } else {
        return (
            <div className='container'>
                <h3 className={classes.title}>{farm_name}</h3>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell key='serial'>
                                Serial
                                </TableCell>
                                <TableCell key='breed'>
                                Breed
                                </TableCell>
                                <TableCell key='age'>
                                Age
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.serial} onClick={()=> handleClick(row)}>
                                    <TableCell>
                                        {row.serial}
                                    </TableCell>
                                    <TableCell>
                                        {row.breed}
                                    </TableCell>
                                    <TableCell >
                                        {row.age}
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        )
    }
   

};
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    title: {
        padding: '20px 0',
    }
});

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
});


export default connect(mapStateToProps, { loadTree })(ViewFarm);


import axios from 'axios';
import {
    FARM_LOADED_SUCCESS,
    FARM_LOADED_FAIL,
    FARM_EMPTY
} from './types';
import API from '../helpers/api'
import Amplify, { Auth, Storage } from 'aws-amplify';


export const getFarmList = (username) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        // const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getRecord/${uuid}/`, config);
        // const signedURL = await Storage.get(`${res.data.tree[0].serial}/trees/1.jpg`);


        // res.data.tree[0].url = signedURL;

        // dispatch({
        //     type: TREE_LOADED_SUCCESS,
        //     payload: res.data
        // });
        const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getUserFarm/${username}/`, config);
        console.log('getFarmList', res.data.farm)

        if (res.data.farm.length > 0) {
            dispatch({
                type: FARM_LOADED_SUCCESS,
                payload: res.data.farm
            });
        } else {
            dispatch({
                type: FARM_EMPTY,
            });
        }


        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: FARM_LOADED_FAIL
        });
    }
};

//  export const clearTreeView = () => dispatch => {
//      dispatch({ type: CLEAR_TREE_VIEW });
//  };

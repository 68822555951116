import React from 'react';
import { connect } from 'react-redux';
import { UploadTree } from '../../actions/tree';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TreeForm from './TreeForm';
import PhotoForm from './PhotoForm';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));



const AddNewTree  =  ({treeUploaded, UploadTree, tree})=> {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [treeDetails, setTreeDetails] = React.useState({tree:[]});
  const [treeImage, setTreeImage] = React.useState({image:{}});
  const [uploading, setUploading] = React.useState(false);
  // React.useEffect(() => {
  //   if(treeUploaded){

  //   }
  // }, []);

  const steps = ['Tree Details', 'Images'];

  const getStepContent = (step)=> {
    switch (step) {
      case 0:
        return <TreeForm handleTreeDetails={(e)=> {handleTreeDetails(e)}} />;
      case 1:
        return <PhotoForm handleTreeImage = {(e)=>{handleTreeImage(e)}}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleTreeDetails = (e) =>{
    setTreeDetails({ ...treeDetails, tree: e });
  };

  const handleTreeImage = (e) =>{
    setTreeImage({ ...treeImage, image: e });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    //console.log(treeImage.image);
    //UploadTree(treeImage.image);
  };
  const handleSubmit = () => {
    setUploading(true);
    UploadTree(treeDetails, treeImage.image).then(()=>{
      setUploading(false);
      navigate('/app/trees');     
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Add new Tree
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Tree addes Successfully
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    >
                      {uploading ? (<CircularProgress color="inherit" />):'Submit'}
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}


const mapStateToProps = state => ({
  treeUploaded: state.tree.treeUploaded
});

export default connect(mapStateToProps, { UploadTree })(AddNewTree);
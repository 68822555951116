import React, { useEffect, useState, setState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { loadTree } from '../actions/tree';
import { Navigate, useNavigate } from 'react-router-dom';

function StickyHeadTable(props) {
    console.log(props);
    const navigate = useNavigate();

    const columns = [
        { id: 'serial', label: 'Serial', minWidth: 100 },
        { id: 'breed', label: 'Breed', minWidth: 170 }
      ];
      
      function createData(serial, breed, uuid, last_modified, diameter, height, age, latitude, longitude) {
        return { serial, breed, uuid, last_modified, diameter, height, age, latitude, longitude };
      }
      

      const rows = [

      ];

      console.log(props.data);
      props.data.map((row)=>{
        rows.push(createData(row.serial, row.breed, row.uuid,
                             row.last_modified, row.diameter, 
                             row.height, row.age, row.latitude, 
                             row.longitude))
      });

      console.log(rows);
      const useStyles = makeStyles({
        root: {
          width: '100%',
        },
        container: {
          maxHeight: 440,
        },
      });




      
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (row) => {
    
 
      props.loadTree(row.serial, row.uuid);
 
    navigate('/app/trees/view');
    

  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid} onClick={()=> handleClick(row)}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

const mapStateToProps = state => ({
  treeLoaded: state.tree.treeLoaded,
  tree: state.tree.tree
});
const mapDispatchToProps = {
  loadTree
};
export default connect(mapStateToProps, mapDispatchToProps)(StickyHeadTable);
import axios from 'axios'
const API = axios.create();

API.interceptors.response.use(
    (response) => {
    return response
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
     
            originalRequest._retry = true;
            return axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`,
                {
                  "refresh": localStorage.getItem('refresh')
                })
                .then(res => {
                    if (res.status === 200) {
                        // 1) put token to LocalStorage
                        localStorage.setItem('access', res.data.access);
     
                        // 2) Change Authorization header
                        axios.defaults.headers.common['Authorization'] = 'JWT ' + localStorage.getItem('access');
     
                        // 3) return originalRequest object with Axios.
                        return axios(originalRequest);
                    }
                })
        }
    }
    
)




export default API
import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user, getRole } from '../actions/auth';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto'
    }
  }));

  
const MainLayout = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
                let authenticate = await props.checkAuthenticated();

                if(!authenticate) {
					return navigate('/login')
                } else {
					const res = await props.load_user();
					await props.getRole(res.username);
				}
        }

        fetchData();
    }, []);

    return (
        <div className={classes.root}>
          <TopBar />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      );
};

export default connect(null, { checkAuthenticated, load_user, getRole })(MainLayout);

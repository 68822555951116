import React, { useState } from 'react';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, getRole, logout } from '../actions/auth';


import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Page from '../components/Page';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));

const Login = ({ login, isAuthenticated, user_role, getRole, logout}) => {
      const classes = useStyles();
      const navigate = useNavigate();
      const [message, setMessage] = useState(false);


	//   useEffect(() => {
	// 	  console.log(1111, isAuthenticated, user_role)
	// 	if (isAuthenticated && user_role == 'ADMIN') {
	// 		return navigate('/app/home');
	// 	} else if (isAuthenticated &&  user_role == 'WORKER') {
	// 		return navigate('/staff/scan');
	// 	}
	// }, [isAuthenticated, user_role])

	// if(isAuthenticated && user_role=='ADMIN') return  <Navigate to='/staff/scan'/>;

	if(isAuthenticated && user_role=='ADMIN') {
		return  <Navigate to='/app/home'/>
	} else  if (isAuthenticated && user_role=='WORKER') {
		return  <Navigate to='/staff/scan'/>
	}

      return (
        <Page
          className={classes.root}
          title="Login"
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  message: ""
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().max(255).required('Username is required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={(values, { setSubmitting ,  setStatus }) => {
                    login(values.username, values.password).then(async(res)=>{
						if (res) {
							getRole(values.username).then(async(user_role)=>{
								setSubmitting(false);
								if (user_role == 'ADMIN') {
									return navigate('/app/home');
								} else if (user_role == 'WORKER') {
									return navigate('/staff/scan');
								} else {
									setMessage(true);
								}
							})
						} else {
							setSubmitting(false);
							setMessage(true);
						}
					});
                }}
              >
				{ ({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
					<form onSubmit={handleSubmit}>
						<Box mb={3}>
						<Typography
							color="textPrimary"
							variant="h2"
						>
							Huaxin - Management System
						</Typography>
						<Typography
							color="textSecondary"
							gutterBottom
							variant="body2"
						>
							Sign in on the internal platform
						</Typography>
						</Box>
						<TextField
						error={Boolean(touched.username && errors.username)}
						fullWidth
						helperText={touched.username && errors.username}
						label="Username"
						margin="normal"
						name="username"
						onBlur={handleBlur}
						onChange={handleChange}
						type="username"
						value={values.username}
						variant="outlined"
						/>
						<TextField
						error={Boolean(touched.password && errors.password)}
						fullWidth
						helperText={touched.password && errors.password}
						label="Password"
						margin="normal"
						name="password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
						/>
						<Box my={2}>
						<Button
							color="primary"
							disabled={isSubmitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							{isSubmitting &&(
							<CircularProgress />
							)}Sign in now
						</Button>
							{message && (<Alert severity="error">Fail</Alert>)}
						</Box>
					</form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user_role: state.auth.user_role
});

export default connect(mapStateToProps, { login, logout, getRole })(Login);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Edit as EditIcon,
  AlignJustify as AlignJustifyIcon
} from 'react-feather';
import NavItem from './NavItem';
import { logout } from '../../../actions/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

/*const user = {
  avatar: '/static/images/avatars/avatar.png',
  jobTitle: 'Manager',
  name: 'Username here'
};*/

const items = [
  {
    href: '/app/home',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/trees',
    icon: ShoppingBagIcon,
    title: 'All Records'
  },

  	{
	  href: '/app/form',
	  icon: EditIcon,
	  title: 'Operation'
	},
  {
    href: '/app/users',
    icon: UserIcon,
    title: 'User Settings'
  },
  {
    href: '/app/operation-settings/pesticide',
    icon: AlignJustifyIcon,
    title: 'Operation Settings'
  },
	{
		href: '/app/category-settings',
		icon: SettingsIcon,
		title: 'Category Settings'
	},
  
];

// const categories = [
//   {
//     href: '/app/category/farm-1',
//     icon: AccountTreeIcon,
//     title: 'Farm 1'
//   },
//   {
//     href: '/app/category/farm-2',
//     icon: AccountTreeIcon,
//     title: 'Farm 4'
//   }
// ];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  textDivider:{
    padding: '7px'
  }
}));



const NavBar = ( {user, onMobileClose, openMobile, logout, farm_navbar, error_loading_farm, farm_empty}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  console.log('openMobile' ,openMobile);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      console.log(1233 ,openMobile);
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const FarmList = () => {

	if (!error_loading_farm) {
		if (farm_empty == true) {
			return (<p className={classes.textDivider}>No Category</p>)
		} else {
			if (farm_navbar.length > 0) {
				return (
				<List>
					{farm_navbar.map((item) => (
					<NavItem
					href={item.href}
					key={item.title}
					title={item.title}
					icon={item.icon}
					/>
					))}
				</List> 
				)
			} else {
				return (
				<CircularProgress style={{alignSelf: 'center'}}/>
				)
			}
		}
		
	} else {
		console.log('Something wrong loading Farm')
		return (
			<p style={{textAlign: 'center'}}>Error loading farm.</p>
		)
	}
	
}

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src='/static/images/avatars/avatar.png'
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user && user.username}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user && user.group}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Typography
          className={classes.textDivider}
          color="secondary"
      >
          Categories
      </Typography>
		<FarmList/> 
      <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              	logout()
				return navigate('/login')
			}}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src='/static/images/avatars/avatar.png'
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user && user.username}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user && user.group}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
       <Divider />
      <Typography
          className={classes.textDivider}
          color="secondary"
      >
          Categories
      </Typography>    
      <FarmList/>  
       <Box flexGrow={1} />
		<Box
			p={2}
			m={2}
			bgcolor="background.dark"
		>
			<Box
			display="flex"
			justifyContent="center"
			mt={2}
			>
			<Button
				color="primary"
				variant="contained"
				onClick={() => {
					logout()
					return navigate('/login')
				}}
			>
				Logout
			</Button>
			</Box>
		</Box> 
   	 </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = state => ({
  user: state.auth.user,
  farm_navbar: state.farm.farm_navbar,
  error_loading_farm: state.farm.error_loading_farm,
  farm_empty: state.farm.farm_empty,
});
export default connect(mapStateToProps, { logout })(NavBar);

import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { getFarmList } from '../../actions/farm';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../../actions/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({checkAuthenticated, load_user, user, getFarmList}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

	useEffect(() => {
		if (user) {
			getFarmList(user.username)
		}
	}, [user])

	useEffect(() => {
		const fetchData = async () => {
			// try {
				let authenticate = await checkAuthenticated();

				if(!authenticate) {
					console.log("🚀 ~ file: index.js ~ line 492 ~ useEffect ~ DashboardLayout", authenticate)
					return navigate('/login')
				} else {
					const res = await load_user();
				}
				// await props.getRole(res.username);
			// } catch (err) {

			// }
		}

		fetchData();
	}, []);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => {setMobileNavOpen(true); console.log(isMobileNavOpen);}} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};



const mapStateToProps = state => ({
	user: state.auth.user
});

export default connect(mapStateToProps, { checkAuthenticated, getFarmList, load_user } )(DashboardLayout);


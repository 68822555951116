import {React, useEffect, useState, setState} from 'react';
import { Link, Redirect, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { treeInfo } from '../../actions/tree';



const useStyles = makeStyles({
  root: {
    width: '90%',
    margin: 'auto'
  },
  media: {
    width: '100%',
  },
  img: {
    width: '100%',
  }
});


const TreeInfo = ({ treeLoaded, tree, treeInfo  }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    let { uuid } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        treeInfo(uuid);
    }, [])


    if(!treeLoaded)
        return (
            <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                    Loading...
              </CardContent>
            </CardActionArea>
          </Card>
        )
    return (
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              title="Image"
            >
                <img className={classes.img} src={tree[0].url} alt="image"/>
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {tree[0].serial}, {tree[0].breed}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Age: {tree[0].age}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Height: {tree[0].height}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Diameter: {tree[0].diameter}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Age: {tree[0].age}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Horizontal Line: {tree[0].horizontal_line}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Latitude: {tree[0].latitude}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Longitude: {tree[0].longitude}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Remark: {tree[0].remark}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );

      
    
    
};


const mapStateToProps = state => ({
    treeLoaded: state.tree.treeLoaded,
    tree: state.tree.tree
});

export default connect(mapStateToProps, { treeInfo })(TreeInfo);

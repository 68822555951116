import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './hocs/DashboardLayout/index';
import MainLayout from './hocs/MainLayout';
import Home from './containers/Home';
import DashboardView from './containers/Dashboard/DashboardView';
import Trees from './containers/Trees';
import TreeView from './containers/TreeView';
import Login from './containers/Login';
import Signup from './containers/Signup';
import AddNewTree from './containers/addtree/AddNewTree';
import EditTree from './containers/edittree/EditTree';
import TreeInfo from './containers/treeInfo/TreeInfo';
import ViewFarm from './containers/Farm/ViewFarm';

import UserList from './containers/User/UserList';

import CategorySetting from './containers/CategorySetting';

// Staff
import MenuLayout from './containers/Staff/MenuLayout';
import FertilizerForm from './containers/Staff/Form/FertilizerForm';
import PestForm from './containers/Staff/Form/PestForm';
import FungicideForm from './containers/Staff/Form/FungicideForm';
import DiseaseForm from './containers/Staff/Form/DiseaseForm';
import PesticideForm from './containers/Staff/Form/PesticideForm';
import ScanQr from './containers/Staff/ScanQr';

// Admin
import AdminFormLayout from './containers/Form/Admin/AdminFormLayout';
import AdminFertilizerForm from './containers/Form/Admin/FertilizerForm';
import AdminPestForm from './containers/Form/Admin/PestForm';
import AdminFungicideForm from './containers/Form/Admin/FungicideForm';
import AdminDiseaseForm from './containers/Form/Admin/DiseaseForm';
import AdminPesticideForm from './containers/Form/Admin/PesticideForm';

import OperationSetting from './containers/OperationSetting/OperationSetting';

const routes = [
	{
		path: 'app',
		element: <DashboardLayout />,
		children: [
			{ path: 'home', element: <DashboardView /> },
			{ path: 'trees', element: <Trees /> },
			{ path: 'trees/view', element: <TreeView /> },
			{ path: 'trees/add', element: <AddNewTree /> },
			{ path: 'trees/edit', element: <EditTree /> },

			{ path: 'farm/:farm_name', element: <ViewFarm /> },

			{ path: 'category-settings', element: <CategorySetting /> },

			{ path: 'users', element: <UserList /> },

			{ path: 'operation-settings/:type', element: <OperationSetting /> },

			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{
		path: 'app/form',
		element: <MainLayout />,
		children: [
			{ path: '/', element: <AdminFormLayout /> },
			{ path: '/fertilizers', element: <AdminFertilizerForm /> },
			{ path: '/pests', element: <AdminPestForm /> },
			{ path: '/fungicides', element: <AdminFungicideForm /> },
			{ path: '/diseases', element: <AdminDiseaseForm /> },
			{ path: '/pesticides', element: <AdminPesticideForm /> },
			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{
		path: 'staff',
		element: <MainLayout />,
		children: [
			{ path: '/', element: <MenuLayout /> },
			{ path: '/scan', element: <ScanQr /> },
			{ path: '/fertilizers', element: <FertilizerForm /> },
			{ path: '/pests', element: <PestForm /> },
			{ path: '/fungicides', element: <FungicideForm /> },
			{ path: '/diseases', element: <DiseaseForm /> },
			{ path: '/pesticides', element: <PesticideForm /> },
			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: 'login', element: <Login /> },
			{ path: 'register', element: <Signup /> },
			{ path: ':uuid', element: <TreeInfo /> },
			{ path: '/', element: <Navigate to="/app/home" /> },
		]
	}
];

export default routes;

// import Category from './containers/Category';
// { path: 'category/:name', element: <Category /> },
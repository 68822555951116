import React from 'react';
import { useState, setState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  img: {
    width: '100%',
  }
}));


export default function PhotoForm({handleTreeImage}) {
  const classes = useStyles();
  const [img, setImg] = useState(null); 
  
  const handleChangeImage = e => {
    setImg(URL.createObjectURL(e.target.files[0]))
    handleTreeImage(e.target.files[0]);
  }
  const imageNotFound = () =>{
    alert("Image Not Found")
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Image
      </Typography>

      <Grid container spacing={3}>   
        <Grid item xs={12}>
          {img !== null && (
            <img className={classes.img} src={img} onError={imageNotFound} alt="image"/>
          )}          
        </Grid>     
        <Grid item xs={12}>
          <div className={classes.root}>
            <input
              accept="image/*"
              className={classes.input}
              id="img"
              multiple
              name="img"
              type="file"
              capture
              onChange={handleChangeImage}
            />
            <label htmlFor="img">
              <Button variant="contained" color="primary" component="span">
                Upload Image
              </Button>
            </label>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
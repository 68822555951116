import React, {useState, useEffect} from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    modalContainer: {
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 'auto',
        marginTop: '200px',
    },
    modalSubmitBtn: {
        textAlign:'right',
        marginTop: 10
    },
})); 

function AddEditModal({openModal, closeModal, onSubmit, data={'title':'Add', 'value': {} }}) {
    const classes = useStyles();
    const [input, setInput] = useState('')

    useEffect(() => {
        if (data.value.id) {
            setInput(data.value.name)
        } else {
            setInput('')
        }
    }, [data.value])


    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modalContainer}>
                <h2>{data.title}</h2>
                <div className={classes.editField}>
                    <TextField
                    fullWidth 
                    onChange={(e)=>{setInput(e.target.value)}}
                    value={input}
                    label="Please input type name" />
                </div>
                <div className={classes.modalSubmitBtn}>
                    <Button onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => {onSubmit(input)}}>
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddEditModal

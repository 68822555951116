import {
    TREE_LOADED_FAIL,
    TREE_LOADED_SUCCESS,
    CLEAR_TREE_VIEW,
    TREE_UPLOAD_SUCCESS,
    TREE_UPLOAD_FAIL,
    TREE_UPDATE_SUCCESS,
    TREE_UPDATE_FAIL,
    TREE_DELETE_SUCCESS,
    TREE_DELETE_FAIL
} from '../actions/types';

const initialState = {
    treeLoaded: false,
    treeUploaded: null,
    treeUpdated: null,
    treeDeleted: null,
    tree: null
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case TREE_LOADED_FAIL:
            return {
                ...state,
                treeLoaded: false
            }
        case TREE_LOADED_SUCCESS:
            return {
                ...state,
                treeLoaded: true,
                tree: payload.tree
            }
        case CLEAR_TREE_VIEW:
            return {
                ...state,
                treeLoaded: false,
                tree: null
            }
        case TREE_UPLOAD_SUCCESS:
            return {
                ...state,
                treeUploaded: true
                
            }
        case TREE_UPLOAD_FAIL:
            return {
                ...state,
                treeUploaded: false
                    
            }
        case TREE_UPDATE_SUCCESS:
            return {
                ...state,
                treeUpdated: true
                        
            }
        case TREE_UPDATE_FAIL:
            return {
                ...state,
                treeUpdated: false
                            
            }
        case TREE_DELETE_SUCCESS:
            return {
                ...state,
                treeDeleted: true
                                
            }
        case TREE_DELETE_FAIL:
            return {
                ...state,
                treeDeleted: false
                                    
            }
        default:
            return state
    }
}

import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';


const Home = ({ isAuthenticated }) => {
    

    // if(!isAuthenticated) {
    //     console.log("🚀 ~ file: Home.js ~ line 10 ~ Home ~ isAuthenticated", isAuthenticated)
    //     return  <Navigate to='/login'/>;
    // }
       
    return (
        <div className='container'>
            <div className="jumbotron mt-5">
                <h1 className="display-4">Huasin Dashboard</h1>
                <p className="lead">This is a preliminary dashboard.</p>
                <hr className="my-4" />
                <p>Go ahead</p>
            </div>
        </div>
    )
    
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Home);

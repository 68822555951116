import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const styles = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
}

export const NodeAddEditModal = ({open, close, data, submit, submitting}) => {
    const [inputs, setInputs] = useState(data)

    useEffect(() => {
        setInputs(data)
    }, [data])

    function handleChange (type, e) {
        setInputs(prev=>({
            ...prev,
            [type]: e.target.value
        }))
    }
    
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="node-add-edit-modal-title"
        >
            <div style={styles.modalContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            label="Type"
                            fullWidth
                            defaultValue={inputs.type}
                            onChange={(e)=>handleChange('type', e)}
                        />
                    </Grid>
                    { data.category == 'disease' || data.category == 'pest' ? (
                        null
                    ) : (
                        <Grid item xs={4}>
                            <TextField
                                label="Amount"
                                type="number"
                                fullWidth
                                defaultValue={inputs.amount || ''}
                                onChange={(e)=>handleChange('amount', e)}
                            />
                        </Grid>
                    )}
                    
                    <Grid item xs={4}>
                        <TextField
                            label="Remark"
                            fullWidth
                            defaultValue={inputs.remark}
                            onChange={(e)=>handleChange('remark', e)}
                        />
                    </Grid>

                </Grid>

                <div style={styles.modalSubmitBtn}>
                    <Button onClick={close} disabled={submitting}>
                        Close
                    </Button>
                    <Button onClick={()=>{submit(inputs)}} disabled={submitting}>
                        {submitting ?('Updating...'):('Submit')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeAddEditModal)

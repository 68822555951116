import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Timeline from './Timeline/Timeline';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({refresh, setRefresh}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
		<AppBar position="static">
			<Tabs 
			value={value} 
			onChange={handleChange} 
			variant="scrollable"
			scrollButtons="auto"
			aria-label="scrollable auto tabs example"
			>
			<Tab label="Dashboard" {...a11yProps(0)} />
			<Tab label="Fruits" {...a11yProps(1)} />
			<Tab label="Pests" {...a11yProps(2)} />
			<Tab label="Fertilizers" {...a11yProps(3)} />
			<Tab label="Disease" {...a11yProps(4)} />
			<Tab label="Pesticide" {...a11yProps(5)} />
			<Tab label="Fungicide" {...a11yProps(6)} />
			</Tabs>
		</AppBar>
		<TabPanel value={value} index={0}>
			<Timeline refresh={refresh} setRefresh={setRefresh} category='all' />
		</TabPanel>
		<TabPanel value={value} index={1}>
			Fruits
		</TabPanel>
		<TabPanel value={value} index={2}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='pest' />
		</TabPanel>
		<TabPanel value={value} index={3}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='fertilizer' />
		</TabPanel>
		<TabPanel value={value} index={4}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='disease' />
		</TabPanel>
		<TabPanel value={value} index={5}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='pesticide' />
		</TabPanel>
		<TabPanel value={value} index={6}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='fungicide' />
		</TabPanel>
    </div>
  );
}
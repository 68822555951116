import {
    LOAD_TREE_INFO,
    STORE_FERTILIZER_FORM,
    STORE_PEST_FORM,
    STORE_FUNGICIDE_FORM,
    STORE_DISEASE_FORM,
    STORE_PESTICIDE_FORM,

    LOAD_CATEGORY_OPTIONS,

    CLEAR_ALL_FORM
} from '../actions/types';

const initialState = {
    tree: {},
    fertilizers: {
        'fertilizer': '',
        'fertilizer_amount': 1
    },
    pests:{
        'pest':'',
        // 'pest_amount':1,
        'pest_images': []
    },
    fungicides:{
        'fungicide':'',
        'fungicide_amount':1
    },
    diseases:{
        'disease':'',
        'disease_amount':1,
        'disease_images': []
    },
    pesticides:{
        'pesticide':'',
        'pesticide_amount':1
    },
    options: []

};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_TREE_INFO:
            return {
                ...state,
                tree: payload,
            }
        case STORE_FERTILIZER_FORM:
            return {
                ...state,
                fertilizers: payload,
            }
        case STORE_PEST_FORM:
            return {
                ...state,
                pests: payload,
            }
        case STORE_FUNGICIDE_FORM:
            return {
                ...state,
                fungicides: payload,
            }
        case STORE_DISEASE_FORM:
            return {
                ...state,
                diseases: payload,
            }
        case STORE_PESTICIDE_FORM:
            return {
                ...state,
                pesticides: payload,
            }
        case LOAD_CATEGORY_OPTIONS:
            return {
                ...state,
                options: payload,
            }
        
        case CLEAR_ALL_FORM:
            return {
                ...state,
                fertilizers: {
                    'fertilizer': '',
                    'fertilizer_amount': 1
                },
                pests:{
                    'pest':'',
                    // 'pest_amount':1,
                    'pest_images': []
                },
                fungicides:{
                    'fungicide':'',
                    'fungicide_amount':1
                },
                diseases:{
                    'disease':'',
                    'disease_amount':1,
                    'disease_images': []
                },
                pesticides:{
                    'pesticide':'',
                    'pesticide_amount':1
                },
                tree:{},
                options:[]
            }
        default:
            return state
    }
}

import axios from 'axios';
import {
    LOAD_TREE_INFO,
    STORE_FERTILIZER_FORM,
    STORE_PEST_FORM,
    STORE_FUNGICIDE_FORM,
    STORE_DISEASE_FORM,
    STORE_PESTICIDE_FORM,

    LOAD_CATEGORY_OPTIONS,

    CLEAR_ALL_FORM
} from './types';
import API from '../helpers/api'
import Amplify, { Auth, Storage } from 'aws-amplify';


export const loadTree = (uuid) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getRecord/${uuid}/`, config);
        if (res) {
            console.log('loadTree', res.data.tree[0])
            dispatch({
                type: LOAD_TREE_INFO,
                payload: res.data.tree[0]
            });
        } else {
            // invalid uuid
            return false
        }

    } catch (err) {
        console.log('formLoadTree', err)
        alert('something wrong!')
        return false

    }
};

export const loadOptions = (category, setLoading) => async dispatch => {
    console.log('loadOptions', category)
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
    setLoading(true)
    try {
        const res = await API.get(`${process.env.REACT_APP_API_URL}/${category}/getNameList/`, config);
        if (res) {
            setLoading(false)
            console.log('loadOptions', res.data.name)
            dispatch({
                type: LOAD_CATEGORY_OPTIONS,
                payload: res.data.name
            });

            return true
        }
        setLoading(false)

    } catch (err) {
        setLoading(false)
        console.log('loadOptions', err)
        alert('something wrong!')
        return false

    }
};

export const storeFertilizer = (data) => async dispatch => {
    console.log('storeFertilizer', data)
    dispatch({
        type: STORE_FERTILIZER_FORM,
        payload: data.fertilizers
    });
};

export const storePest = (data) => async dispatch => {
    console.log('storePest', data)
    dispatch({
        type: STORE_PEST_FORM,
        payload: data.pests
    });
};

export const storeFungicide = (data) => async dispatch => {
    console.log('storeFungicide', data)
    dispatch({
        type: STORE_FUNGICIDE_FORM,
        payload: data.fungicides
    });
};

export const storeDisease = (data) => async dispatch => {
    console.log('storeDisease', data)
    dispatch({
        type: STORE_DISEASE_FORM,
        payload: data.diseases
    });
};

export const storePesticide = (data) => async dispatch => {
    console.log('storePesticide', data)
    dispatch({
        type: STORE_PESTICIDE_FORM,
        payload: data.pesticides
    });
};

export const clearAll = () => async dispatch => {
    console.log('clearAll')
    dispatch({
        type: CLEAR_ALL_FORM,
    });
};

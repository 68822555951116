import React, {useState,useEffect} from 'react'

import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,IconButton,Paper, Modal, TextField, MenuItem, Select, InputLabel} from '@material-ui/core';

import {Delete as DeleteIcon, Create as CreateIcon, Lock as LockIcon} from '@material-ui/icons';
import axios from 'axios';
import API from '../../helpers/api'
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';


const styles = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
}

const defaultUserForm = {
    'username':'',
    'password':'',
    'company':'',
    'first_name':'',
    'last_name':'',
    'email':'',
    'group':
    'ADMIN'
}

const type = ['add','edit','password']

const UserList = () => {
    const [users, setUsers] = useState([])
    const [addModalStatus, setAddModalStatus] = useState(false)
    const [addEdit, setAddEdit] = useState(type[0])
    const [userForm, setUserForm] = useState({
        'username':'Test',
        'password':'test',
        'company':'test',
        'first_name':'test',
        'last_name':'test',
        'email':'test@test.com',
        'group':'ADMIN'
    })

    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('access')) {
            console.log(localStorage.getItem('access'))
            getUserList()
        } else {
            console.log('none')
        }
    }, [])

    const getUserList = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getUserList/`).then( (response) => { 
            setUsers(response.data.userlist)
        })
    }

    function handleInputChange(type, e) {
        // console.log('handleInputChange', e.target.value)
        setUserForm( prev => ({
            ...prev,
            [type]: e.target.value
        }))

    }

    async function handleSubmit() {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token 535261045567516f2108c7b8029b3d96f95ceddc`,
            }
        };

        if (addEdit == 'add') {
            axios.post(`${process.env.REACT_APP_API_URL}/registerUser/`, userForm, config).then( (response) => {
                console.log(11, response)
                setAddModalStatus(false)
                getUserList()
                alert(response.data.message)
            }).catch(err=> {
                alert(err.response.data.message)
            })
        } else if (addEdit == 'edit') {
            axios.put(`${process.env.REACT_APP_API_URL}/user/` + userForm.username + '/', userForm, config).then( (response) => {
                console.log(11, response)
                setAddModalStatus(false)
                getUserList()
                alert(response.data.message)
            }).catch(err=> {
                alert(err.response.data.message)
            })
        } else if (addEdit == 'password') {
            alert('TODO')
            // @TODO UPDATE USER PASSWORD

            // axios.put(`${process.env.REACT_APP_API_URL}/changePassword/`, { 
            //     "password": "123", 
            //     "new_password": userForm.password, 
            //     "username":[userForm.username] 
            // }, config).then( (response) => {
            //     console.log(11, response)


            //     setAddModalStatus(false)
            //     getUserList()
            //     alert(response.data.message)
            // }).catch(err=> {
            //     alert(err.response.data.message)
            // })
        }

        
    }

    // PAGINATION
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div style={{padding: '20px'}}>
            <div style={{padding: 20,  textAlign:'end'}}>
                <Button style={{width: '20%'}}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        setUserForm(defaultUserForm)
                        setAddModalStatus(true)
                        setAddEdit(type[0])
                    }}>
                    Add User
                </Button>
            </div>
            <TableContainer component={Paper}> 
                <Table style={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">USERNAME</TableCell>
                            <TableCell align="right">EMAIL</TableCell>
                            <TableCell align="right">FIRST NAME</TableCell>
                            <TableCell align="right">LAST NAME</TableCell>
                            <TableCell align="right">ROLE</TableCell>
                            <TableCell align="right">STATUS</TableCell>
                            <TableCell align="right">ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {users.length > 0 ?  (users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((u) => (
                        <TableRow key={u.username}>
                            <TableCell align="right" component="th" scope="row">{u.username}</TableCell>
                            <TableCell align="right">{u.email}</TableCell>
                            <TableCell align="right">{u.first_name}</TableCell>
                            <TableCell align="right">{u.last_name}</TableCell>
                            <TableCell align="right">{u.group}</TableCell>
                            <TableCell align="right">{u.is_active}</TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="update-password" onClick={() => {
                                    setUserForm({
                                        'username': u.username,
                                        'password': '',
                                        'company': '',
                                        'first_name':u.first_name,
                                        'last_name':u.last_name,
                                        'email':u.email,
                                        'group':u.group,
                                    })
                                    setAddModalStatus(true)
                                    setAddEdit(type[2])
                                }}>
                                    <LockIcon />
                                </IconButton>
                                <IconButton aria-label="update"  onClick={() => {
                                    setUserForm({
                                        'username': u.username,
                                        'password': '',
                                        'company': '',
                                        'first_name':u.first_name,
                                        'last_name':u.last_name,
                                        'email':u.email,
                                        'group':u.group,
                                    })
                                    setAddModalStatus(true)
                                    setAddEdit(type[1])
                                }}>
                                    <CreateIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))) : 
                    (<p>No Data</p>)}
                    </TableBody>
                </Table>
            </TableContainer>

            
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />

                     
            <Modal
                open={addModalStatus}
                onClose={() => { setAddModalStatus(false)}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                    <div style={styles.modalContainer}>
                        <div>
                            <p>{addEdit == 'edit' ? 'Update Info' : ( (addEdit == 'add' ? 'Add New User' : 'Change Password') ) }</p>
                        </div>
                        <div >
                            <TextField 
                            fullWidth
                            required
                            onChange={(e) => handleInputChange('username', e)}
                            value={userForm.username}
                            disabled={ ((addEdit=='edit' || addEdit == 'password') ? true : false) }
                            label="Username"/>
                            <TextField 
                            fullWidth 
                            required
                            onChange={(e) => handleInputChange('password', e)}
                            disabled={ (addEdit=='edit' ? true : false) }
                            value={userForm.password}
                            label="Password" />
                            <TextField 
                            fullWidth 
                            required
                            onChange={(e) => handleInputChange('company', e)}
                            disabled={ ((addEdit=='edit' || addEdit == 'password') ? true : false) }
                            value={userForm.company}
                            label="Company" />
                            <TextField 
                            fullWidth 
                            required
                            onChange={(e) => handleInputChange('first_name', e)}
                            value={userForm.first_name}
                            disabled={ (addEdit == 'password' ? true : false) }
                            label="First Name" />
                            <TextField 
                            fullWidth 
                            required
                            onChange={(e) => handleInputChange('last_name', e)}
                            value={userForm.last_name}
                            disabled={ (addEdit == 'password' ? true : false) }
                            label="Last Name" />
                            <TextField 
                            fullWidth 
                            required
                            onChange={(e) => handleInputChange('email', e)}
                            value={userForm.email}
                            disabled={ (addEdit == 'password' ? true : false) }
                            label="Email" />
                            
                            <div style={{display:'flex', marginTop: 10}}>
                                <InputLabel id="group-label" style={{alignSelf:'center', flex: 1}}>Group</InputLabel>
                                <Select
                                    style={{flex: 3}}
                                    disabled={ (addEdit == 'password' ? true : false) }
                                    fullWidth 
                                    labelId="group-label"
                                    id="group"
                                    value={userForm.group}
                                    onChange={e => {
                                        setUserForm( prev => ({
                                            ...prev,
                                            'group': e.target.value
                                        }))
                                    } }>
                                    <MenuItem key='ADMIN' value='ADMIN'>ADMIN</MenuItem>
                                    <MenuItem key='WORKER' value='WORKER'>WORKER</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div >
                            <Button onClick={()=>{setAddModalStatus(false)}}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={handleSubmit}>
                                {addEdit=='edit' ? ('UPDATE') : ('SUBMIT')}
                            </Button>
                        </div>
                    </div>
            </Modal>   
        </div>
    )
}
// let users = [{
//     "username": "richardkhoo",
//     "is_active": true,
//     "email": "richard@hphttech.com",
//     "last_name": "",
//     "first_name": "Richard",
//     "group": "WORKER"
// }, {
//     "username": "aquametrics",
//     "is_active": true,
//     "email": "admin@cloudatik.com",
//     "last_name": "Khoo",
//     "first_name": "Richard",
//     "group": "ADMIN"
// }, {
//     "username": "demo",
//     "is_active": true,
//     "email": "demo@demo.com",
//     "last_name": "",
//     "first_name": "demo",
//     "group": null
// }, {
//     "username": "bekzat",
//     "is_active": true,
//     "email": "bekzat.998@gmail.com",
//     "last_name": "",
//     "first_name": "Bekzat",
//     "group": null
// }, {
//     "username": "demo123",
//     "is_active": true,
//     "email": "demo123@mail.ru",
//     "last_name": "",
//     "first_name": "demo123",
//     "group": "WORKER"
// }, {
//     "username": "calvin",
//     "is_active": true,
//     "email": "",
//     "last_name": "Teoh",
//     "first_name": "Calvin",
//     "group": "ADMIN"
// }, {
//     "username": "sean",
//     "is_active": true,
//     "email": "",
//     "last_name": "Ooi",
//     "first_name": "Sean",
//     "group": "WORKER"
// }, {
//     "username": "demo-123",
//     "is_active": true,
//     "email": "demo123@gmail.com",
//     "last_name": "123",
//     "first_name": "Demo",
//     "group": null
// }, {
//     "username": "sam",
//     "is_active": true,
//     "email": "",
//     "last_name": "Chong",
//     "first_name": "Sam",
//     "group": "ADMIN"
// }, {
//     "username": "worker",
//     "is_active": true,
//     "email": "",
//     "last_name": "Worker",
//     "first_name": "Woker",
//     "group": "WORKER"
// }, {
//     "username": "worker-sam",
//     "is_active": true,
//     "email": "",
//     "last_name": "",
//     "first_name": "worker sam",
//     "group": null
// }, {
//     "username": "hansheng",
//     "is_active": true,
//     "email": "",
//     "last_name": "",
//     "first_name": "Han",
//     "group": "ADMIN"
// }, {
//     "username": "cctan",
//     "is_active": true,
//     "email": "",
//     "last_name": "Tan",
//     "first_name": "CC",
//     "group": "ADMIN"
// }, {
//     "username": "employee",
//     "is_active": true,
//     "email": "",
//     "last_name": "Worker",
//     "first_name": "Employee",
//     "group": "WORKER"
// }, {
//     "username": "testing",
//     "is_active": true,
//     "email": "bekzat@cloudatik.com",
//     "last_name": "Khoo",
//     "first_name": "Bekzat",
//     "group": "WORKER"
// }, {
//     "username": "durian",
//     "is_active": true,
//     "email": "",
//     "last_name": "",
//     "first_name": "Sean",
//     "group": "ADMIN"
// }];

export default UserList

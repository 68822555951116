import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {
    FARM_LOADED_FAIL,
    FARM_LOADED_SUCCESS,
    FARM_EMPTY
} from '../actions/types';

const initialState = {
    farms: [],
    farm_navbar: [],
    error_loading_farm: false,
    farm_empty: false,
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case FARM_LOADED_SUCCESS:
            let temp = []
            payload.map(val => {
                temp.push({
                    href: '/app/farm/' + val,
                    icon: AccountTreeIcon,
                    title: val
                })
            })
            return {
                ...state,
                farms: payload,
                farm_navbar: temp
            }
        case FARM_EMPTY:
            return {
                ...state,
                farm_empty: true,
            }
        case FARM_LOADED_FAIL:
            return {
                ...state,
                error_loading_farm: true,
            }
        default:
            return state
    }
}

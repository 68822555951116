export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ROLE_SUCCESS = 'ROLE_SUCCESS';

export const TREE_LOADED_FAIL = 'TREE_LOADED_FAIL';
export const TREE_LOADED_SUCCESS = 'TREE_LOADED_SUCCESS';
export const CLEAR_TREE_VIEW = 'CLEAR_TREE_VIEW';
export const TREE_UPLOAD_SUCCESS = 'TREE_UPLOAD_SUCCESS';
export const TREE_UPLOAD_FAIL = 'TREE_UPLOAD_FAIL';
export const TREE_UPDATE_FAIL = 'TREE_UPDATE_FAIL';
export const TREE_UPDATE_SUCCESS = 'TREE_UPDATE_SUCCESS';
export const TREE_DELETE_SUCCESS = 'TREE_DELETE_SUCCESS';
export const TREE_DELETE_FAIL = 'TREE_DELETE_FAIL';

export const FARM_LOADED_SUCCESS = 'FARM_LOADED_SUCCESS';
export const FARM_EMPTY = 'FARM_EMPTY';
export const FARM_LOADED_FAIL = 'FARM_LOADED_FAIL';

export const LOAD_TREE_INFO = 'LOAD_TREE_INFO';
export const STORE_FERTILIZER_FORM = 'STORE_FERTILIZER_FORM';
export const STORE_PEST_FORM = 'STORE_PEST_FORM';
export const STORE_FUNGICIDE_FORM = 'STORE_FUNGICIDE_FORM';
export const STORE_DISEASE_FORM = 'STORE_DISEASE_FORM';
export const STORE_PESTICIDE_FORM = 'STORE_PESTICIDE_FORM';
export const LOAD_CATEGORY_OPTIONS = 'LOAD_CATEGORY_OPTIONS';
export const CLEAR_ALL_FORM = 'CLEAR_ALL_FORM';
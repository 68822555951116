import React, {useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { storePesticide, loadOptions } from '../../../actions/form';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';

const PesticideForm = ({ pesticides, storePesticide, tree, loadOptions, options }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [pesticide, setPesticide] = React.useState('');
    const [pesticideAmount, setPesticideAmount] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    useEffect(async() => {
        setPesticide(pesticides['pesticide'])
        setPesticideAmount(pesticides['pesticide_amount'])
        loadOptions('pesticide', setLoading)
    }, [pesticides])

    const selectChange = (e) => {
        setPesticide(e.target.value)
    }
    const amountChange = (e) => {
        setPesticideAmount(e.target.value)
    }
    const clear = () => {
        setPesticide('')
        setPesticideAmount(1)
    }
    const save = () => {
        storePesticide({
            'pesticides': {
                'pesticide': pesticide,
                'pesticide_amount': pesticideAmount
            }
        })
        return navigate('/staff?uuid='+ tree.uuid)
    }

    const back = () => {
        clear()
        return navigate('/staff?uuid='+ tree.uuid)
    }
    
    return (
        <div className={classes.root}>
            {loading && (
                <div className={classes.loader}>
                    <CircularProgress/>
                </div>
            )}
            <h3>Pesticide</h3>

            <FormControl className={classes.formControl}>
                <>
                    <InputLabel id="pest-label">Pesticide</InputLabel>
                    <Select
                        labelId="pesticide-label"
                        id="pesticide"
                        value={pesticide}
                        onChange={e => selectChange(e)}
                    >
                        {options.map( (key,value) => {
                            return (
                                <MenuItem key={value} value={key}>{key}</MenuItem>
                            )
                        })}
                    </Select>
                </>
                <>
                    <TextField label="Amount" value={pesticideAmount} type="number" onChange={e => amountChange(e)}/>
                </>
            </FormControl>

            <Grid container className={classes.submitRow}>
                <Grid item sm={6} xs={12} style={{textAlign:'start', marginTop: '10px', paddingRight: '5px'}}>
                    <Button style={{width: '100%'}}
                        variant="outlined"
                        color="secondary"
                        onClick={back}
                        endIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Grid>
                <Grid item sm={3} xs={6} style={{marginTop: '10px'}}>
                    <Button style={{width: '100%'}}
                        variant="contained"
                        color="secondary"
                        onClick={clear}
                        endIcon={<DeleteIcon />}>
                        Clear
                    </Button>
                </Grid>
                <Grid item sm={3} xs={6} style={{marginTop: '10px', paddingLeft: '5px'}}>
                    <Button style={{width: '100%'}}
                        variant="contained"
                        color="primary"
                        onClick={save}
                        endIcon={<SaveIcon />}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 60px'
    },
    formControl: {
        width: '100%',
        marginTop: '20px',
        '& > div' : {
            margin: '20px 0',
        }
    },
    submitRow: {
        textAlign: 'center',
    },
    loader: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        right: 0,
        background: '#fff',
        opacity: 0.7,
        textAlign: 'center',
        
    }
}));  
const mapStateToProps = state => ({
    pesticides: state.form.pesticides,
    tree: state.form.tree,
    options: state.form.options,
});
export default connect(mapStateToProps, { storePesticide, loadOptions })(PesticideForm)
// rfce

import React from 'react'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const classes = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
}

export const NodeViewModal = ({open, close, data}) => {

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="node-view-modal-title"
        >
            <div style={classes.modalContainer}>
                    <GridList cellHeight={160} cols={3} style={data.images.length > 0 ? {width: 'auto', height: 350,} : {}}>
                    {data.images.length > 0 && (
                        data.images.map( (img) => {
                            return (
                                <>
                            <Grid item xs={3} style={{padding: '0 10px'}} onClick={()=>{ return window.open(img, '_blank', 'noopener,noreferrer') }}>
                                <img src={img}  alt="image" style={{width: '200px', height: 'auto'}}/>
                            </Grid>
                            </>)
                        })
                    )}
                    </GridList>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            label="Serial"
                            fullWidth
                            defaultValue={data.serial}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Category"
                            fullWidth
                            defaultValue={data.category}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Type"
                            fullWidth
                            defaultValue={data.type}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>
                    { data.category == 'disease' || data.category == 'pest'  ? (
                        null
                    ) : (
                        <Grid item xs={4}>
                            <TextField
                                label="Amount"
                                fullWidth
                                defaultValue={data.amount}
                                InputProps={{readOnly: true,}}
                            />
                        </Grid>
                    )}

                    <Grid item xs={4}>
                        <TextField
                            label="Datetime"
                            fullWidth
                            defaultValue={data.datetime}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Submitted By"
                            fullWidth
                            defaultValue={data.user}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Remark"
                            fullWidth
                            defaultValue={data.remark}
                            InputProps={{readOnly: true,}}
                        />
                    </Grid>


                </Grid>

                <div style={classes.modalSubmitBtn}>
                    <Button onClick={close}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default NodeViewModal

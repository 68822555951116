
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'

const ScanQr = ()  => {
    const classes = useStyles();
    const navigate = useNavigate();
    // const [result, setResult] = useState('No Result')
    const APP_URL = `${process.env.REACT_APP_APP_URL}/`

    const handleScan = data => {
        if (data) {
            let result = data.replace(APP_URL,'')
            return navigate('/staff?uuid='+result)
        }
    }
    const handleError = err => {
        console.error(err)
    }
      
    return (
        <>
        <div className={classes.root} >
            <Grid container >
                <Grid item xs={12}>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%'}}
                        showViewFinder={true}
                        className={classes.qrWrapper}
                    />
                </Grid>
            </Grid>
           

            {/* <p>{result}</p> */}
        </div>
        </>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 20px',
        textAlign:'center'
    },
    qrWrapper:{
        '& section div':{
            border: '22px solid rgba(0, 0, 0, 0.3) !important'
    }
    }
}));  
const mapStateToProps = state => ({
});
export default connect(mapStateToProps , {})(ScanQr);
